import Api from "@/Api";

import LoadingOverlay from "@/components/global/LoadingOverlay";

export default {
	components: {
		LoadingOverlay
	},
	props: {
		showNavbarProp: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	metaInfo() {
		const subTitle = this.navbarTitel;
		return {
			titleTemplate: () => {
				return `BX: ${subTitle}`;
			}
		};
	},
	data() {
		return {
			loading: false,
			editable: false,
		};
	},
	computed: {
		user: {
			get() {
				if (this.$store) return this.$store.state.user;
				return null;
			},
			set(value) {
				this.$store.commit("setUser", value);
			}
		},
		userName() {
			if (this.$store) return this.$store.getters.name;
			return "";
		}
	},
	created() {
		if (!this.user)
			Api.get("user/").then(response => {
				this.user = response.data;
			});
	},
	watch: {},
	mounted() { },
	methods: {
		//Sortiert Listeninhalte nach Datum, wenn dessen key den substring "datum" oder "date" enthält
		//beim "b-table"-Element property ":sort-compare="sortDates"" hinzufügen
		sortDates(datumA, datumB, key) {
			if (!key.includes("datum") && !key.includes("date")) {
				return false;
			} else {
				let a = null;
				let b = null;

				if (key.includes(".")) {
					a = this.resolve(key, datumA);
					b = this.resolve(key, datumB);
				}
				else {
					a = datumA[key];
					b = datumB[key];
				}
				if (a && b) {
					a = a.split(".");
					b = b.split(".");
					a =
						parseInt(a[2], 10) * 10000 +
						parseInt(a[1], 10) * 100 +
						parseInt(a[0]);
					b =
						parseInt(b[2], 10) * 10000 +
						parseInt(b[1], 10) * 100 +
						parseInt(b[0]);
					return a - b;
				} else {
					return false;
				}
			}
		},
		resolve(path, obj = self, separator = '.') {
			var properties = Array.isArray(path) ? path : path.split(separator)
			return properties.reduce((prev, curr) => prev && prev[curr], obj)
		}
	},
};
